.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bp5-navbar {
  z-index: 30;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pulse {
  width: 15px; /* Moderately increased size */
  height: 15px;
  border-radius: 50%;
  background: #e33; /* Slightly brighter color */
  position: relative;
  animation: pulse 2s infinite;
}

.pulse::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e33;
  border-radius: 50%;
  box-shadow: 0 0 15px #e33; /* Moderately pronounced shadow */
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(227, 51, 51, 0.5); /* Adjusted starting point with moderate opacity */
  }
  70% {
    box-shadow: 0 0 0 20px rgba(227, 51, 51, 0); /* Moderately large expansion */
  }
  100% {
    box-shadow: 0 0 0 0 rgba(227, 51, 51, 0);
  }
}

@keyframes slow-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slow-spin {
  animation: slow-spin 20s linear infinite;
}
